<template>
  <div class="main">
    <div class="title">
      <div class="title_icon"></div>
      <div class="title_title">
        <div class="title_txt">政策匹配</div>
      </div>
      <div v-if="mainName" class="title_record" @click="goToRecord">
        <div class="title_record_icon"></div>
        匹配记录
      </div>
    </div>
    <div class="content">
      <div class="content-input flex">
        <div v-if="mainName" class="autocomplete_right" slot="prefix"></div>
        <i v-else class="el-icon-warning-outline" style="color: #ff9035;margin-left: 10px;" slot="prefix"></i>
        <div :class="[ mainName ? 'content-name' : 'content-name_no' ]" @click="handleBind">{{ mainName ? mainName : '首次匹配请先绑定主体' }}</div>
      </div>
      <el-button type="primary" @click="handleSelect">{{ mainName ? '开始匹配' : '立即绑定' }}</el-button>
    </div>
    <el-dialog
      title="绑定主体"
      :visible.sync="dialogVisible"
      width="500px">
      <component
        v-if="dialogVisible"
        :is="componentName"
        @changeBindStatus="changeBindStatus"
        @dialogClose="dialogClose"
      ></component>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { request } from '@/network';
import { getMemberId } from '@/assets/public/utils/token';

export default {
  data () {
    return {
      mainName: JSON.parse(localStorage.getItem('mainName')),
      userEntity: JSON.parse(localStorage.getItem('userEntity')),
      dialogVisible: false,
      componentName: 'bindCompany'
    };
  },
  mounted () {
    this.getAccountInformation();
  },
  methods: {
    changeBindStatus (data) {
      const { entityId, memberId, name } = data;
      this.mainName = name;
      this.userEntity = {
        entityName: name,
        entityId: entityId,
        memberId: memberId
      };
    },
    dialogClose () {
      this.dialogVisible = false;
    },
    handleBind () {
      if (this.mainName) return;
      this.dialogVisible = true;
    },
    ...mapMutations('simpleMatching', [ 'setComponentName', 'setCompanyName' ]),
    handleSelect () {
      if (this.mainName) {
        // this.userEntity.entityName = this.mainName;
        this.setCompanyName(this.userEntity.entityName);
        this.setComponentName('Waiting');
      } else {
        this.dialogVisible = true;
      }
    },
    async getAccountInformation () {
      if (getMemberId() !== '') {
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/PersonalCenter/accountInformation',
          params: {
            id: getMemberId(),
          },
        });
        if (res.code !== 200) return this.$message.error('信息获取失败');
        this.mainName = res.data[0].name;
      }
    },
    goToRecord () {
      this.$router.push('/simpleMatching/record');
    },
  },
};
</script>

<style scoped lang="less">
.main {
  // height: 400px;
  background-color: rgba(48, 120, 240, 0.05);
  margin: 39px 0 47px;
  padding: 39px 44px 47px;
}
.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .title_icon {
    width: 84px;
    height: 84px;
    background-image: url("../../../../../assets/pc/images/simpleMatching/seach_left.png");
    // background-image: url("~@/assets/pc/images/simpleMatching/search_left.png");
    background-size: 84px 84px;
  }
  .title_title {
    margin-left: 32px;
    .title_txt {
      font-size: 32px;
      // font-weight: bold;
    }
  }
  .title_record {
    cursor: pointer;
    margin-top: 20px;
    margin-left: 30px;
    // position: absolute;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    .title_record_icon {
      margin-right: 3px;
      width: 18px;
      height: 18px;
      background-image: url("../../../../../assets/pc/images/simpleMatching/record.png");
      background-size: 18px 18px;
    }
  }
}
.content {
  margin-top: 55px;
  text-align: center;
  cursor: pointer;
  .content-input {
    width: 770px;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
    font-size: 24px;
    justify-content: left;
    .content-name {
      margin-left: 20px;
      color: rgba(16, 16, 16, 100);
      font-size: 18px;
    }
    .content-name_no {
      margin-left: 10px;
      color: #aaadb1;
      font-size: 18px;
    }
    .autocomplete_right {
      margin-left: 11px;
      width: 25px;
      height: 25px;
      background-image: url("../../../../../assets/pc/images/simpleMatching/company.png");
      background-size: 25px 25px;
    }
  }
}
</style>


