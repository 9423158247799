<template>
  <div class="main">
    <div style="position: relative;">
      <component
        :is="componentName"
      >
      </component>
    </div>
    <!-- <open v-else @upgrade="upgrade"></open> -->
  </div>
</template>

<script>
import { request } from '../../../../network';
import Search from './component/search.vue';
import Waiting from './component/waiting.vue';
import { mapState, mapMutations } from 'vuex';
export default {
  components: {
    Search,
    Waiting,
  },
  data () {
    return {
    };
  },
  beforeRouteLeave (to, from, next) {
    this.setComponentName('Search');
    next();
  },
  computed: {
    ...mapState('simpleMatching', [ 'componentName' ]),
    isOpen () {
      return this.$store.state.login.isVip === '普通用户' ? true : false;
    }
  },
  methods: {
    ...mapMutations('simpleMatching', [ 'setComponentName' ]),
     upgrade () {
      this.$message({
          message: '已向客服发送请求成为协会会员通知',
          type: 'success'
        });
    }
  }
};
</script>

<style scoped lang="less">

</style>
